import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { breakpoints, spacing, fontStyle } from '@naf/theme';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { HttpStatus } from '../../components/HttpStatus';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { MAIN_URL } from '../../lib/constants';

interface NotFoundProps {
  withoutLayout?: boolean;
}

export const NotFound = ({ withoutLayout = false }: NotFoundProps) => {
  const { staticContext } = useContext(__RouterContext);
  if (staticContext) {
    staticContext.statusCode = 404;
  }

  return withoutLayout ? (
    <NotFoundInner />
  ) : (
    <LayoutWithMainContent
      title="NAF - 404"
      description="404 - Vi finner ikke siden du leter etter"
      url=""
      imgUrl=""
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <NotFoundInner />
    </LayoutWithMainContent>
  );
};

const NotFoundInner = () => {
  const title = 'Kjørt deg fast?';
  const description = 'Trykk på pilene for å finne veien videre, eller gå tilbake til forsiden.';
  const buttonText = 'Gå til forsiden';

  const [baseUrl, setBaseUrl] = useState(MAIN_URL);

  useEffect(() => {
    setBaseUrl(window.location.origin);
  }, []);

  const [NotFoundImage, setNotFoundImage] = useState<null | ReactNode>(null);

  const handleVehicleClick = useCallback(() => {
    if (baseUrl) {
      window.location.href = `${baseUrl}/bilverksted-og-tester`;
    }
  }, [baseUrl]);

  const handleElbilClick = useCallback(() => {
    if (baseUrl) {
      window.location.href = `${baseUrl}/elbil`;
    }
  }, [baseUrl]);

  const handleBenefitsClick = useCallback(() => {
    if (baseUrl) {
      window.location.href = `${baseUrl}/medlemskap/medlemsfordeler`;
    }
  }, [baseUrl]);

  const handleTravelClick = useCallback(() => {
    if (baseUrl) {
      window.location.href = `${baseUrl}/bilferie-og-reise`;
    }
  }, [baseUrl]);

  useEffect(() => {
    const dynamicImport = async () => {
      const dynamicallyImportedComponent = await import('../../components/NotFoundImage');
      const Component = dynamicallyImportedComponent.default;
      setNotFoundImage(
        <Component
          onVehicleClick={handleVehicleClick}
          onElbilClick={handleElbilClick}
          onBenefitsClick={handleBenefitsClick}
          onTravelClick={handleTravelClick}
        />,
      );
    };
    dynamicImport();
  }, [handleVehicleClick, handleElbilClick, handleBenefitsClick, handleTravelClick]);

  return (
    <Container role="main">
      <Wrapper>
        <Grid>
          <StyledGridCol s="12" m="12" l="6" xl="6">
            <HttpStatus code={404}>
              <Header variant={TextVariant.Header1}>{title}</Header>
              <StyledText tag="p" variant={TextVariant.ArticleTextLarge}>
                {description}
              </StyledText>
              <StyledButton variant="signature" size="large" href={baseUrl}>
                {buttonText}
              </StyledButton>
              <StyledButtonMobile variant="signature" size="small" href={baseUrl}>
                {buttonText}
              </StyledButtonMobile>
            </HttpStatus>
          </StyledGridCol>
          <StyledImageGridCol s="12" m="12" l="6" xl="6">
            {NotFoundImage}
          </StyledImageGridCol>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export const Container = styled.main``;

export const StyledGridCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${spacing.space56};

  @media (max-width: ${breakpoints.m}) {
    align-items: center;
    padding-bottom: 0;
  }
`;

export const StyledImageGridCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 75%;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  margin-top: ${spacing.space48};
  @media (min-width: ${breakpoints.l}) {
    img {
      margin-left: ${spacing.space80};
    }
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space40};
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const StyledButtonMobile = styled(Button)`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: flex;
    margin-bottom: ${spacing.space16};
  }
`;

export const Header = styled(Text)`
  margin: 0;
  font-size: 4rem;
  line-height: 4.5rem;
  @media (max-width: ${breakpoints.m}) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: ${spacing.space24};
  }
`;

export const StyledText = styled(Text)`
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space40};
  @media (max-width: ${breakpoints.m}) {
    font-size: ${fontStyle?.article?.articleText['font-size'] || '1.125rem'};
    line-height: ${fontStyle?.article?.articleText['line-height'] || '1.75rem'};
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space24};
    text-align: center;
  }
`;
